/* Coustom */
img{
  object-fit: cover;
}
tbody tr:nth-child(odd) {
  background-color: #f4f6fd;
}
.mostly-customized-scrollbar::-webkit-scrollbar {
  width: 5px;

  background-color: #e52323; /* or add it to the track */
}
.css-yk16xz-control {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
  padding-left: 10px !important;
  border: 1px solid #f0f1f5 !important;
  border-radius: 12px !important;
}
.css-1pahdxg-control {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
  border-color: #2684ff !important;
  border-radius: 12px !important;
  box-shadow: 0 0 0 0 #2684ff !important;
}

.suggestions {
  
  padding: 4px;
  border: 1px solid gray;
  border-radius: 0.75rem;
  margin-bottom: 10px;
}
.suggestion {
  
  padding: 5px 10px;
 
  cursor: pointer;
}

.suggestion:hover {
  background-color: #f0f0f0;
 
}
.suggestion:first-of-type {
  border-bottom: 1px solid gray;
}

.checkbox-container {
  display: flex;
  flex-direction: column;
}

.checkbox-row {
  display: flex;
  justify-content: space-between;
}

.checkbox-item {
  flex: 1;
  /* margin-right: 10px; */
}

.checkbox-item:last-child {
  margin-right: 0;
}